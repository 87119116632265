@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  /* background-image: url("/public/images/background.png"); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensures background doesn't scroll with content */
  background-repeat: no-repeat;
  position: relative;
  color: #000;
  z-index: 1;
}

body .bg {
  /* background-image: url("/public/images/bg.jpeg"); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensures background doesn't scroll with content */
  background-repeat: no-repeat;
  position: relative;
  color: #000;
  z-index: 1;
}

body .landing {
  background-image: url("/public/images/bgImage.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.fade-in {
  animation: fadeIn 1s ease forwards;
}

.top {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bump {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

.bumping {
  animation: bump 2s infinite;
}

.swiper-slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 480px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
